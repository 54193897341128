import Markdown from "../../../utilities/Markdown";
import Unslider from "../../../utilities/Unslider";
import BlockScroller from "../BlockScroller";
import {buildCssUrlString} from "../../../../utils/StringUtilities";
import styles from './styles/MessageBlock.module.scss';
import classNames from 'classnames';
import ErrorBlock from "../ErrorBlock";
import {getCdnUrl} from "../../../../utils/SchoolBlocksUtilities";
import ClickableLink from "../../../utilities/ClickableLink";
import {isJson} from "../../../admin/spinup/utilities";
import {IBlockComponentProps} from "../_Block";
import {PropsWithChildren, useState} from "react";
import {CSSTransition} from "react-transition-group";

const slideClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

function MessageLinkContainer(props: PropsWithChildren<{
    href: string,
    title?: string,
}>) {
    const {href, title, children} = props;

    if (href) {
        return <ClickableLink href={href} title={title}>
            {children}
        </ClickableLink>
    } else {
        return <>{children}</>
    }
}

export const MessageBlockColors = {
    LIGHT: "light",
    DARK: "dark",
}

interface IMessageBlockProps extends IBlockComponentProps {
    blockObj: IMessageBlockObj,
}

export default function MessageBlock(props: IMessageBlockProps) {
    const [currentUnsliderIndex, setCurrentUnsliderIndex] = useState(0);

    try {
        // in PHP the json_data we get back is a string... but it sends jsonObj, so set that if it exists.
        let json_data = props.blockObj.blockModel.json_data;
        if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data);

        let items = json_data.block.settings.items;
        if (!Array.isArray(items)) {
            // handle legacy format
            items = Object.values(items);
        }

        items = items.map(item => ({
            ...item,
            displayTextUnderImage: !!item.displayTextUnderImage, // not sure if necessary anymore, but ported from PHP logic
        }));
        const url = json_data.block.settings.url;
        const scrollbarColor = json_data.block.settings.color === MessageBlockColors.DARK ? "#FFF" : "rgba(0, 0, 0, .4)";
        const colorCSS = json_data.block.settings.color === MessageBlockColors.DARK ? "sb-organization-color-block-bg" : "sb-organization-color-block-font";

        let body;
        if (items.length === 1) {
            const item = items[0];
            const hasTitle = !!item.title;
            const hasImage = !!item.image;
            const hasMessage = !!item.message;
            const hasAltText = !!item.altText;
            const altText = hasAltText ? item.altText : "";
            const hasMessageUrl = !!item.messageUrl;
            const displayTextUnderImage = item.displayTextUnderImage;
            const hideFromAssistiveTech = (!hasTitle && !hasMessage && hasImage && !hasAltText)
            const imageUrl = getCdnUrl(item.image);

            const singleBlockScrollerClassName = classNames({
                [colorCSS]: true,
                [styles.blockScroller]: true,
            })

            body = <BlockScroller blockRef={props.blockRef}
                                  blockObj={props.blockObj}
                                  htmlId={props.htmlId}
                                  setShowExpandButton={props.setShowExpandButton}
                                  outsideGrid={props.outsideGrid}
                                  color={scrollbarColor}
                                  aria-hidden={hideFromAssistiveTech}
                                  className={singleBlockScrollerClassName}>
                {(hasImage && displayTextUnderImage) ?
                    <MessageLinkContainer href={item.messageUrl} title={item.title}>
                        {hasTitle && <div className={`sb-blockHead ${hasImage ? "" : colorCSS}`} role="heading"
                                          data-blocktype={props.blockObj.blockType}>
                            <span>{item.title}</span>
                        </div>}
                        <img className="sb-message-block-image" alt={altText}
                             src={imageUrl} style={{"position": "relative"}}/>
                        {hasMessage && <div className="sb-blockContent ">
                            <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                        </div>}
                    </MessageLinkContainer> :
                    <MessageLinkContainer href={item.messageUrl} title={item.title}>
                        {hasImage && <div className="sb-message-block-image sb-message-block-background-image"
                                          role="img" aria-label={altText} title={altText}
                                          style={{"backgroundImage": buildCssUrlString(imageUrl)}}/>}
                        {hasTitle && <div className={`sb-blockHead ${hasImage ? "" : colorCSS}`}
                                          data-blocktype={props.blockObj.blockType}>
                            <span>{item.title}</span>
                        </div>}
                        {hasMessage && <div className="sb-blockContent ">
                            <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                        </div>}
                    </MessageLinkContainer>}
            </BlockScroller>
        } else {
            body = <div className={`${colorCSS}`} data-blocktype={props.blockObj.blockType}>
                <Unslider
                    delay={5000}
                    blockId={props.blockObj.id}
                    containerClassName={styles.unsliderContainer}
                    currentIndex={currentUnsliderIndex}
                    setCurrentIndex={setCurrentUnsliderIndex}
                    controlsStyles={styles.mobilePositionControls}
                >
                    {items && items.map((item, i) => {
                        const hasTitle = !!item.title;
                        const hasImage = !!item.image;
                        const hasMessage = !!item.message;
                        const hasAltText = !!item.altText;
                        const altText = hasAltText ? item.altText : "";
                        const hasMessageUrl = !!item.messageUrl;
                        const hideFromAssistiveTech = (!hasTitle && !hasMessage && hasImage && !hasAltText)
                        const imageUrl = getCdnUrl(item.image);

                        const blockScrollerClassName = classNames({
                            ['sb-message-slider']: true,
                            [colorCSS]: true,
                            [styles.blockScroller]: true,
                        })

                        return <CSSTransition key={i} in={i === currentUnsliderIndex} classNames={slideClassNames} timeout={1000}>
                            <div className={styles.slide} style={{visibility: i === currentUnsliderIndex ? "visible" : "hidden"}}>
                                <BlockScroller blockRef={props.blockRef}
                                               blockObj={props.blockObj}
                                               htmlId={props.htmlId}
                                               setShowExpandButton={props.setShowExpandButton}
                                               outsideGrid={props.outsideGrid}
                                               color={scrollbarColor} key={i}
                                               aria-hidden={hideFromAssistiveTech}
                                               className={blockScrollerClassName}>
                                    {(hasImage && !!item.displayTextUnderImage) ?
                                        <MessageLinkContainer href={item.messageUrl} title={item.title}>
                                            <div id={`messageBlockSlide-${i}`}>
                                                {hasTitle && <div className={`sb-msgHead`}>
                                                    <span>{item.title}</span>
                                                </div>}
                                                <img className="sb-message-block-image" alt={altText}
                                                     src={encodeURI(imageUrl)} style={{"position": "relative"}}/>
                                                {hasMessage && <div className="sb-blockContent ">
                                                    <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                                                </div>}
                                            </div>
                                        </MessageLinkContainer> :
                                        <MessageLinkContainer href={item.messageUrl} title={item.title}>
                                            <div id={`messageBlockSlide-${i}`}>
                                                {hasImage &&
                                                    <div className="sb-message-block-image sb-message-block-background-image"
                                                         role="img" aria-label={altText} title={altText}
                                                         style={{"backgroundImage": buildCssUrlString(imageUrl)}}/>}
                                                {hasTitle && <div className={`sb-msgHead`}>
                                                    <span>{item.title}</span>
                                                </div>}
                                                {hasMessage && <div className="sb-blockContent ">
                                                    <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                                                </div>}
                                            </div>
                                        </MessageLinkContainer>}
                                </BlockScroller>
                            </div>
                        </CSSTransition>
                    })}
                </Unslider>
            </div>
        }

        return <div id={`sb-block-message-${props.blockObj.id}`} className={`sb-block-message ${url ? 'sb-url-block' : ''}`}
                    style={{"position": "static"}} data-target={url}>
            {body}
        </div>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
}
